const FeatureTypeFilterOptions = [
    {
        Weapons: [
            'Shooter',
            'Blaster',
            'Roller',
            'Brush',
            'Charger',
            'Bucket',
            'Splatling',
            'Dualie',
            'Brella',
            'Stringer',
            'Splatana',
        ],
    },
    'Sub',
    'Special',
    'NPC',
    'Brand',
    'Octarian Army',
    'Salmonid',
    'Key Item',
];
export { FeatureTypeFilterOptions };
