<script lang="ts">
</script>

<footer>
  Arty Siege is a fan project, and is not affiliated with Nintendo or Splatoon.
  <h3>
    All art remains property of the respective artists. Files on this site are provided for personal use only - do not
    produce copies to sell.
  </h3>
  <div class="social-media-row">
    <div class="social-media-link">
      <a href="https://twitter.com/tableturfproj" target="_blank" rel="noreferrer">
        <img alt="twitter.com/tableturfproj" src="../img/Site/icon-twitter.svg" />
      </a>
    </div>
    <div class="social-media-link">
      <a href="https://tumblr.com/tableturfproj" target="_blank" rel="noreferrer">
        <img alt="tumblr.com/tableturfproj" src="../img/Site/icon-tumblr.svg" />
      </a>
    </div>
  </div>
</footer>

<style>
  footer {
    background: var(--theme-background-accent);
    width: 100%;
    padding: 20px 0;
    color: white;
  }
  .social-media-row {
    display: flex;
    justify-content: center;
  }
  .social-media-link {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
  @media print {
    footer {
      display: none;
    }
  }
</style>
